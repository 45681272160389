<template>
  <div class="home-3">
    <!-- Tampilan Navbar -->
    <navigation />
    <main class="main">
      <!-- Tampilan Banner Website -->
      <div
        class="d-none d-lg-block"
        style="margin-top: 6%; transform: translateY(-18px)"
      >
        <!-- <v-carousel
          hide-delimiters
          :cycle="true"
          :interval="5000"
          :items-per-page="2"
          :hide-controls="true"
          :height="calculateHeight"
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-img :src="slide.url_slider" :alt="slide.alt"></v-img>
          </v-carousel-item>
        </v-carousel> -->
        <div class="containerr">
          <div>
            <img
              style="width: 100%; height: auto"
              src="../assets/img/homepage/banner/eid_mubarak.jpeg"
              alt="Popup Image"
            />
            <!-- <div class="flex">
              <div class="haha">
                <span
                  class="d-none d-lg-block"
                  style="
                    z-index: 1;
                    color: #fff;
                    font-size: 72px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 116.496px; /* 161.8% */
                  "
                  >Kendarai mobil impian Anda
                </span>
                <br />
                <span
                  class="d-none d-lg-block"
                  style="
                    z-index: 1;
                    color: #fff;
                    font-size: 72px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 116.496px; /* 161.8% */
                  "
                  >bersama kami sekarang!</span
                >
                <button
                  class="mt-2 find-buttonn d-none d-lg-block"
                  @click="goToTestDriveForm"
                >
                  <span class="txt-drive">Test Drive</span>
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- Tampilan Banner Mobile -->
      <div
        class="d-lg-none"
        style="margin-top: 7%; transform: translateY(-18px)"
      >
        <navigation />
        <div class="containerr">
          <div class="image-containerr">
            <img
              src="../assets/img/homepage/banner/autocom.png"
              alt="Popup Image"
            />
          </div>
        </div>
      </div>

      <!-- Tampilan Website Pencarian -->
      <div class="d-none d-lg-block" style="background-color: #f9f9f9">
        <div class="container">
          <div class="find-car-form contoh-search" style="box-shadow: 100%">
            <h4
              class="find-car-title"
              style="color: #1f1c1c !important; font-family: Poppins"
            >
              Cari Mobil Impian Anda
            </h4>
            <form action="#">
              <div>
                <div>
                  <div class="container">
                    <div style="position: relative; z-index: 9999" class="row">
                      <div class="col-lg-3 col-sm-12">
                        <div class="car-sort-box">
                          <div class="car-search-form">
                            <form action="#">
                              <div class="form-group">
                                <!-- haha -->
                                <label
                                  style="color: black; font-family: Poppins"
                                  >Pencarian</label
                                >
                                <input
                                  v-model="searchKey"
                                  type="text"
                                  class="form-control"
                                  style="border-radius: 5px; height: 40px"
                                  placeholder="Pencarian"
                                  @keydown.enter.prevent=""
                                  @input="handleInput"
                                />

                                <button type="button" @click="searchCars">
                                  <i
                                    style="margin-top: 29px"
                                    class="far fa-search"
                                  ></i>
                                </button>
                              </div>
                              <!-- Tampilkan hasil pencarian jika isOpen bernilai true -->
                              <ul v-if="isOpen" class="search-results">
                                <li
                                  v-for="(result, index) in results"
                                  :key="index"
                                  @click="handledetaillates(result.slug)"
                                >
                                  <i class="flaticon-drive">
                                    <b> {{ result.name }}</b></i
                                  >
                                  - {{ result.brand }} -
                                  <i class="flaticon-gas-station">
                                    {{ result.bbm }}</i
                                  >
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Brand</label
                          >

                          <el-select
                            style="width: 100%"
                            v-model="selectedMerek"
                            multiple
                            placeholder="Pilih Brand"
                            filterable
                          >
                            <el-option
                              v-for="merek in merekOptions"
                              :key="merek.nama_merek"
                              :label="merek.nama_merek"
                              :value="merek.nama_merek"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Transmisi</label
                          >
                          <el-select
                            style="width: 100%"
                            v-model="selectedTransmisi"
                            multiple
                            placeholder="Pilih Transmisi"
                            filterable
                          >
                            <el-option
                              v-for="transmisi in transmisiOptions"
                              :key="transmisi.transmisi_name"
                              :label="transmisi.transmisi_name"
                              :value="transmisi.transmisi_name"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Bahan Bakar</label
                          >
                          <el-select
                            style="width: 100%"
                            v-model="selectedBbm"
                            multiple
                            placeholder="Pilih Bahan Bakar"
                            filterable
                          >
                            <el-option
                              v-for="bbm in bbmOptions"
                              :key="bbm.bbm_name"
                              :label="bbm.bbm_name"
                              :value="bbm.bbm_name"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="col-lg-3 col-sm-12">
                        <div class="form-group">
                          <label style="color: white">Transmisi</label>
                          <button
                            @click="searchCars"
                            type="button"
                            class="btn-find"
                            style="font-family: Poppins"
                          >
                            <span class="far fa-search"></span> Cari Mobil
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                  <div class="col-md-6 car-sort-box">
                    <div class="car-search-form">
                      <span
                        style="
                          color: var(--color-blue-blue-06, #207ACE);
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                          letter-spacing: 0.4px;
                        "
                        >Search</span
                      >
                      <form action="#">
                        <div class="form-group">
                          <input
                            v-model="searchKey"
                            type="text"
                            class="form-control"
                            placeholder="Search"
                          />
                          <button type="button" @click="searchCars">
                            <i class="far fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label style="color: white">Transmisi</label>
                      <button
                        @click="searchCars"
                        type="button"
                        class="btn-find"
                      >
                        <span class="far fa-search"></span> Cari Mobil
                      </button>
                    </div>
                  </div>
                </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Tampilan mobile Pencarian -->
      <div class="d-lg-none" style="background-color: #f9f9f9">
        <div class="container" style="padding-left: 30px; padding-right: 30px">
          <div class="find-car-form contoh-search" style="box-shadow: 100%">
            <h4
              class="find-car-title"
              style="color: #1f1c1c !important; font-family: Poppins"
            >
              Cari Mobil Impian Anda
            </h4>
            <form action="#">
              <div>
                <div>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-2 col-sm-12">
                        <div class="car-sort-box">
                          <div class="car-search-form">
                            <form action="#">
                              <div class="form-group">
                                <label
                                  style="color: black; font-family: Poppins"
                                  >Pencarian</label
                                >
                                <input
                                  v-model="searchKey"
                                  type="text"
                                  class="form-control"
                                  style="
                                    border-radius: 5px;
                                    height: 40px;
                                    font-size: 16px;
                                  "
                                  placeholder="Pencarian"
                                  @keydown.enter.prevent=""
                                />

                                <button type="button" @click="searchCars">
                                  <i
                                    style="margin-top: 29px"
                                    class="far fa-search"
                                  ></i>
                                </button>
                              </div>
                              <ul v-if="isOpen" class="search-resultss">
                                <li
                                  v-for="(result, index) in results"
                                  :key="index"
                                  @click="handledetaillates(result.slug)"
                                >
                                  <i class="flaticon-drive">
                                    <b> {{ result.name }}</b></i
                                  >
                                  -
                                  <i class="flaticon-gas-station">
                                    {{ result.bbm }}</i
                                  >
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Brand</label
                          >

                          <el-select
                            style="width: 100%"
                            v-model="selectedMerek"
                            multiple
                            placeholder="Pilih Brand"
                            filterable
                          >
                            <el-option
                              v-for="merek in merekOptions"
                              :key="merek.value"
                              :label="merek.label"
                              :value="merek.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Transmisi</label
                          >
                          <el-select
                            style="width: 100%"
                            v-model="selectedTransmisi"
                            multiple
                            placeholder="Pilih Transmisi"
                            filterable
                          >
                            <el-option
                              v-for="transmisi in transmisiOptions"
                              :key="transmisi.value"
                              :label="transmisi.label"
                              :value="transmisi.value"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-12">
                        <div class="form-group">
                          <label style="color: black; font-family: Poppins"
                            >Bahan Bakar</label
                          >
                          <el-select
                            style="width: 100%"
                            v-model="selectedBbm"
                            multiple
                            placeholder="Pilih Bahan Bakar"
                            filterable
                          >
                            <el-option
                              v-for="bbm in bbmOptions"
                              :key="bbm.value"
                              :label="bbm.label"
                              :value="bbm.value"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                          <label style="color: white">Transmisi</label>
                          <button
                            @click="searchCars"
                            type="button"
                            class="btn-find"
                            style="font-family: Poppins"
                          >
                            <span class="far fa-search"></span> Cari Mobil
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                  <div class="col-md-6 car-sort-box">
                    <div class="car-search-form">
                      <span
                        style="
                          color: var(--color-blue-blue-06, #207ACE);
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                          letter-spacing: 0.4px;
                        "
                        >Search</span
                      >
                      <form action="#">
                        <div class="form-group">
                          <input
                            v-model="searchKey"
                            type="text"
                            class="form-control"
                            placeholder="Search"
                          />
                          <button type="button" @click="searchCars">
                            <i class="far fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label style="color: white">Transmisi</label>
                      <button
                        @click="searchCars"
                        type="button"
                        class="btn-find"
                      >
                        <span class="far fa-search"></span> Cari Mobil
                      </button>
                    </div>
                  </div>
                </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Tampilan Mobil Listrik Website -->
      <!-- Top Category Section -->
      <div
        class="d-none d-lg-block search-results-container"
        style="padding-top: -5%; background-color: #f9f9f9"
        ref="resultsContainer"
      >
        <div class="bg py-60">
          <div>
            <div class="d-flex justify-content-center">
              <span
                style="
                  color: var(--Color-Blue-Blue-05, #207ace);
                  font-family: Poppins;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                "
              >
                Mobil
                <span
                  style="
                    color: var(--Color-Neutral-Neutral-07, #504e4e);
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    font-family: Poppins;
                  "
                  >Listrik</span
                >
              </span>
            </div>
            <div class="container" v-if="isLoading">
              <v-slide-group
                mobile-break-point="1000"
                show-arrows
                center-active
              >
                <v-skeleton-loader
                  v-for="i in limit"
                  :key="i"
                  class="mx-auto border mr-4"
                  width="230px"
                  type="image, article"
                ></v-skeleton-loader>
              </v-slide-group>
            </div>
            <div class="container" v-else>
              <div class="test" style="margin-left: -20px; margin-right: -20px">
                <v-slide-group
                  mobile-break-point="1000"
                  show-arrows
                  center-active
                >
                  <template v-slot:next>
                    <v-icon color="orange" large>mdi-chevron-right</v-icon>
                  </template>
                  <template v-slot:prev>
                    <v-icon color="orange" large>mdi-chevron-left</v-icon>
                  </template>
                  <v-slide-item
                    v-for="c in topCategory.slice(0, limit)"
                    :key="c.id"
                    class="mr-5"
                  >
                    <div
                      class="wow fadeInUp car-item"
                      data-wow-delay=".25s"
                      style="
                        box-shadow: 20px;
                        background: white;
                        border-radius: 10px;
                        padding: 10px;
                        width: 270px;
                        height: auto;
                        margin-bottom: 25px;
                        margin-top: 25px;
                        box-shadow: -1px 0 5px 0 rgb(0, 0, 0, 0.15);
                        transition: var(--transition);
                      "
                    >
                      <div>
                        <center>
                          <img
                            :src="c.image"
                            class="img-card"
                            alt=""
                            @error="handleImageError"
                          />
                        </center>
                      </div>
                      <div class="car-content">
                        <div class="car-top">
                          <h4>
                            <a
                              style="
                                color: black;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100%;
                                font-weight: 500;
                                font-family: Poppins;
                              "
                              href="#"
                              >{{ c.name }}</a
                            >
                          </h4>
                          <span
                            style="color: #207ace; font-family: Poppins"
                            class="car-price"
                            >Rp {{ formatPrice(c.range_harga) }}</span
                          >
                        </div>
                        <v-row no-gutters>
                          <v-col>
                            <v-chip
                              class="mt-2"
                              closable
                              style="padding: 5px"
                              color="#207ACE"
                              text-color="#207ACE"
                              label
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 500;
                                  font-size: 14px;
                                  font-family: Poppins;
                                "
                                >{{ c.transmisi }} | {{ c.bbm }}</span
                              ></v-chip
                            >
                          </v-col>
                        </v-row>
                        <button
                          @click="handledetaillates(c.slug)"
                          class="btn-detail"
                          style="font-family: Poppins"
                        >
                          Detail Mobil
                        </button>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tampilan  Mobil Listrik Mobile  -->
      <div class="d-lg-none">
        <div class="car-area bg py-60">
          <div
            class="container"
            style="padding-left: 30px; padding-right: 30px"
          >
            <div class="d-flex justify-content-left mt-2">
              <h5
                class="site-title"
                style="
                  font-size: 20px !important;
                  color: #207ace;
                  font-family: Poppins;
                "
              >
                Mobil <span style="color: #504e4e">Listrik</span>
              </h5>
            </div>
            <!-- Tampilan Skeleton Latest Listing -->
            <div class="container" v-if="isLoading">
              <v-slide-group>
                <!-- ... -->
                <!-- Pastikan jumlah v-skeleton-loader sesuai dengan limit -->
                <v-skeleton-loader
                  v-for="i in limit"
                  :key="i"
                  class="mx-auto border mr-4"
                  width="230px"
                  type="image, article"
                ></v-skeleton-loader>
              </v-slide-group>
            </div>

            <!-- Tampilan Produk Latest Listing -->
            <div class="" v-else>
              <br />
              <div class="test">
                <v-slide-group>
                  <v-slide-item
                    v-for="c in topCategory.slice(0, limit)"
                    :key="c.id"
                    class="mr-2"
                  >
                    <!-- Tampilan data mobil -->
                    <div
                      class="wow fadeInUp car-item"
                      @click="handledetaillates(c.slug)"
                      data-wow-delay=".25s"
                      style="
                        display: flex;
                        padding: 10px 6px;
                        flex-direction: column;
                        gap: 5px;
                        border-radius: 4px;
                        background: var(--Color-Neutral-Neutral-00, #fff);
                        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                        z-index: -999999;
                      "
                    >
                      <div style="display: flex; justify-content: center">
                        <img
                          :src="c.imagePath"
                          style="max-width: 120px; height: 80px"
                          alt=""
                          @error="handleImageError"
                        />
                      </div>
                      <div class="car-content">
                        <div class="car-top">
                          <h4>
                            <a
                              style="
                                color: #000;
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.22px;
                                font-family: Poppins;
                              "
                              href="#"
                              >{{ c.name }}</a
                            >
                          </h4>
                          <span
                            style="
                              color: var(--Color-Blue-Blue-05, #207ace);
                              font-size: 11px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              letter-spacing: 0.22px;
                              font-family: Poppins;
                            "
                            >Rp {{ formatPrice(c.rangeHarga) }}</span
                          >
                        </div>
                        <v-row no-gutters>
                          <v-col>
                            <v-chip
                              class="mt-2"
                              closable
                              style="padding: 10px; height: 20px"
                              color="#207ACE"
                              text-color="#207ACE"
                              label
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 300;
                                  font-size: 10px;
                                  font-family: Poppins;
                                "
                                >{{ c.transmisi }} | {{ c.bbm }}</span
                              ></v-chip
                            >
                          </v-col>
                          <v-col
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: flex-end;
                            "
                          >
                            <div>
                              <li></li>
                            </div>
                          </v-col>
                        </v-row>
                        <button
                          @click="handledetaillates(c.slug)"
                          class="btn-detail-res"
                          style="font-family: Poppins"
                        >
                          Detail Mobil
                        </button>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tampilan Jenis Mobil Website  -->
      <div class="d-none d-lg-block" style="background-color: #f9f9f9">
        <div class="container" style="background-color: #f9f9f9">
          <br />
          <br />
          <br />
          <div class="site-heading text-center">
            <span
              style="
                color: var(--Color-Blue-Blue-05, #207ace);
                /* Headline */
                font-family: Poppins;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              "
            >
              Jenis
              <span
                style="
                  color: var(--Color-Neutral-Neutral-07, #504e4e);
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  font-family: Poppins;
                "
                >Mobil</span
              >
            </span>
          </div>
          <div class="mt-5 container">
            <div class="row" style="margin-left: -20px; margin-right: -20px">
              <div
                class="col-6 col-md-4 col-lg-4 pr-2 image-jenis no-padding-margin"
                v-for="(type, index) in carJenis.slice(0, limit)"
                :key="type.id"
              >
                <img
                  :src="type.image"
                  :alt="type.jenis_name"
                  class="image"
                  style="width: 100%; height: auto"
                  @mouseover="showText(index)"
                  @mouseout="hideText(index)"
                  @click.prevent="selectType(type)"
                />
                <div
                  @click.prevent="selectType(type)"
                  class="overlay"
                  :class="{ 'show-overlay': hoverIndex === index }"
                >
                  <p
                    @click.prevent="selectType(type)"
                    style="font-size: 52px; font-weight: 600"
                  >
                    {{ type.jenis_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-5 container">
            <div class="row">
              <div
                class="col-6 col-md-4 col-lg-4 pr-2"
                v-for="type in carJenis.slice(0, limit)"
                :key="type.jenis_id"
              >
                <a
                  href="#"
                  style="
                    padding: 0;

                    margin-bottom: 0;
                    width: 100%;
                  "
                  @click.prevent="selectType(type)"
                >
                  <div>
                    <img
                      style="width: 100%; height: auto; flex-shrink: 0"
                      :src="type.image"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- Tampilan Jenis Mobil Mobile  -->
      <div class="d-lg-none">
        <div class="car-area bg py-60">
          <div
            class="container"
            style="padding-left: 30px; padding-right: 30px"
          >
            <div class="d-flex justify-content-left mt-2">
              <h5
                class="site-title"
                style="
                  font-size: 20px !important;
                  color: #207ace;
                  font-family: Poppins;
                "
              >
                Jenis <span style="color: #504e4e">Mobil</span>
              </h5>
            </div>
            <br />

            <div class="row">
              <div
                class="col-6 col-md-4 col-lg-4 pr-2 image-jenis"
                v-for="(type, index) in carJenis.slice(0, limit)"
                :key="type.id"
              >
                <img
                  :src="type.image"
                  :alt="type.jenis_name"
                  class="image"
                  style="width: 100%; height: auto"
                  @mouseover="showText(index)"
                  @mouseout="hideText(index)"
                  @click.prevent="selectType(type)"
                />
                <div
                  @click.prevent="selectType(type)"
                  class="overlay"
                  :class="{ 'show-overlay': hoverIndex === index }"
                >
                  <p
                    @click.prevent="selectType(type)"
                    style="font-size: 52px; font-weight: 600"
                  >
                    {{ type.jenis_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tampilan Jenis Merk Website  -->
      <div class="d-none d-lg-block car-area bg py-60">
        <div class="">
          <br />
          <br />
          <br />
          <div class="d-flex justify-content-center mt-5">
            <span
              style="
                color: var(--Color-Blue-Blue-05, #207ace);
                /* Headline */
                font-family: Poppins;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              "
            >
              Merk
              <span
                style="
                  color: var(--Color-Neutral-Neutral-07, #504e4e);
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  font-family: Poppins;
                "
                >Mobil</span
              >
            </span>
          </div>
          <br />
          <br />
          <br />
          <div
            class="mt-5 container-fluid"
            style="padding-left: 150px; padding-right: 150px"
          >
            <div
              class="row justify-content-center"
              style="margin-left: -20px; margin-right: -20px"
            >
              <div
                class="col-6 col-md-4 col-lg-2"
                v-for="brand in carBrands.slice(0, 12)"
                :key="brand.id_merek"
              >
                <div
                  @click="selectBrand(brand)"
                  data-wow-delay=".25s"
                  style="
                    box-shadow: 20px;
                    border-radius: 10px;
                    padding: 10px;
                    width: 270px;
                    cursor: pointer;
                    height: auto;
                    margin-bottom: 25px;
                    margin-top: 25px;
                    transition: var(--transition);
                    display: flex;
                    flex-direction: column;
                    align-items: center; /* Memposisikan konten di tengah vertikal */
                    justify-content: center; /* Memposisikan konten di tengah horizontal */
                  "
                >
                  <div class="image-container">
                    <img
                      @click="selectBrand(brand)"
                      :src="brand.logo"
                      alt=""
                      @error="handleImageError"
                    />
                  </div>

                  <div class="car-content">
                    <div class="car-top">
                      <!-- <h4 style="text-align: center">
                    <a
                        style="
                        color: black;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 100%;
                        "
                        href="#"
                        @click="selectBrand(brand)"
                        >{{ brand.nama_merek }}</a
                    >
                </h4> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tampilan Jenis Merk Mobile  -->
      <div class="d-lg-none">
        <div class="car-area bg py-60" style="background-color: #f9f9f9">
          <div
            class="container"
            style="padding-left: 30px; padding-right: 30px"
          >
            <div class="d-flex justify-content-left mt-2">
              <h5
                class="site-title"
                style="
                  font-size: 20px !important;
                  color: #207ace;
                  font-family: Poppins;
                "
              >
                Merk <span style="color: #504e4e">Mobil</span>
              </h5>
            </div>
            <br />

            <div class="row">
              <div
                class="col-4 p-0"
                v-for="brand in carBrands.slice(0, 12)"
                :key="brand.id_merek"
              >
                <div
                  class="brand-container"
                  @click="selectBrand(brand)"
                  data-wow-delay=".25s"
                >
                  <div>
                    <img
                      @click="selectBrand(brand)"
                      :src="brand.logo"
                      alt=""
                      @error="handleImageError"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>

      <!-- Tampilan Banner Test Drive Website -->
      <div class="mt-3 d-none d-lg-block containerr">
        <div>
          <img
            src="../assets/img/homepage/banner/drive_banner.jpg"
            style="display: flex; align-items: center; width: 100%"
            alt="Popup Image"
          />
        </div>
      </div>
      <!-- Tampilan Banner Test Drive Mobile -->
      <div class="d-lg-none containerr">
        <div>
          <img
            src="../assets/img/homepage/banner/drive_banner.jpg"
            style="display: flex; align-items: center; width: 100%"
            alt="Popup Image"
          />
        </div>
        <!-- <div class="image-containerr">
          <img
            @click="goToTestDriveForm"
            src="../assets/img/homepage/banner-down.png"
            alt="Popup Image"
          />
          <span
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
              color: #fff;
              text-align: center;
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
            >Takut mobil tidak sesuai? Test Drive-in aja!
          </span>
        </div> -->
      </div>
      <!-- Tampilan Mobil Popular Website -->
      <div
        class="d-none d-lg-block"
        style="padding-top: 2%; background-color: #f9f9f9"
        ref="resultsContainer"
      >
        <div class="car-area bg py-60">
          <div>
            <div class="d-flex justify-content-center mt-5">
              <span
                style="
                  color: var(--Color-Blue-Blue-05, #207ace);
                  font-family: Poppins;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                "
              >
                Mobil
                <span
                  style="
                    color: var(--Color-Neutral-Neutral-07, #504e4e);
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    font-family: Poppins;
                  "
                  >Popular</span
                >
              </span>
            </div>
            <div class="container" v-if="isLoading">
              <v-slide-group
                mobile-break-point="1000"
                show-arrows
                center-active
              >
                <v-skeleton-loader
                  v-for="i in limit"
                  :key="i"
                  class="mx-auto border mr-4"
                  width="230px"
                  type="image, article"
                ></v-skeleton-loader>
              </v-slide-group>
            </div>
            <div class="container" v-else>
              <div class="test" style="margin-left: -20px; margin-right: -20px">
                <v-slide-group
                  mobile-break-point="1000"
                  show-arrows
                  center-active
                >
                  <template v-slot:next>
                    <v-icon color="orange" large>mdi-chevron-right</v-icon>
                  </template>
                  <template v-slot:prev>
                    <v-icon color="orange" large>mdi-chevron-left</v-icon>
                  </template>
                  <v-slide-item
                    v-for="c in topView.slice(0, limit)"
                    :key="c.id"
                    class="mr-5"
                  >
                    <div
                      class="wow fadeInUp car-item"
                      data-wow-delay=".25s"
                      style="
                        box-shadow: 20px;
                        background: white;
                        border-radius: 10px;
                        padding: 10px;
                        width: 270px;
                        height: auto;
                        margin-bottom: 25px;
                        margin-top: 25px;
                        box-shadow: -1px 0 5px 0 rgb(0, 0, 0, 0.15);
                        transition: var(--transition);
                      "
                    >
                      <div>
                        <center>
                          <img
                            :src="c.image"
                            class="img-card"
                            alt=""
                            @error="handleImageError"
                          />
                        </center>
                      </div>
                      <div class="car-content">
                        <div class="car-top">
                          <h4>
                            <a
                              style="
                                color: black;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100%;
                                font-weight: 500;
                                font-family: Poppins;
                              "
                              href="#"
                              >{{ c.name }}</a
                            >
                          </h4>
                          <span
                            style="color: #207ace; font-family: Poppins"
                            class="car-price"
                            >Rp {{ formatPrice(c.range_harga) }}</span
                          >
                        </div>
                        <v-row no-gutters>
                          <v-col>
                            <v-chip
                              class="mt-2"
                              closable
                              style="padding: 5px"
                              color="#207ACE"
                              text-color="#207ACE"
                              label
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 500;
                                  font-size: 14px;
                                  font-family: Poppins;
                                "
                                >{{ c.transmisi }} | {{ c.bbm }}</span
                              ></v-chip
                            >
                          </v-col>
                        </v-row>
                        <button
                          @click="handledetaillates(c.slug)"
                          class="btn-detail"
                          style="font-family: Poppins"
                        >
                          Detail Mobil
                        </button>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tampilan  Mobil Popular Mobile  -->
      <div class="d-lg-none">
        <div class="car-area bg py-60">
          <div
            class="container"
            style="padding-left: 30px; padding-right: 30px"
          >
            <div class="d-flex justify-content-left mt-2">
              <h5
                class="site-title"
                style="
                  font-size: 20px !important;
                  color: #207ace;
                  font-family: Poppins;
                "
              >
                Mobil <span style="color: #207ace">Popular</span>
              </h5>
            </div>
            <!-- Tampilan Skeleton Latest Listing -->
            <div class="container" v-if="isLoading">
              <v-slide-group>
                <!-- ... -->
                <!-- Pastikan jumlah v-skeleton-loader sesuai dengan limit -->
                <v-skeleton-loader
                  v-for="i in limit"
                  :key="i"
                  class="mx-auto border mr-4"
                  width="230px"
                  type="image, article"
                ></v-skeleton-loader>
              </v-slide-group>
            </div>

            <!-- Tampilan Produk Latest Listing -->
            <div class="" v-else>
              <br />
              <div class="test">
                <v-slide-group>
                  <v-slide-item
                    v-for="c in topView.slice(0, limit)"
                    :key="c.id"
                    class="mr-2"
                  >
                    <!-- Tampilan data mobil -->
                    <div
                      class="wow fadeInUp car-item"
                      @click="handledetaillates(c.slug)"
                      data-wow-delay=".25s"
                      style="
                        display: flex;
                        padding: 10px 6px;
                        flex-direction: column;
                        gap: 5px;
                        border-radius: 4px;
                        background: var(--Color-Neutral-Neutral-00, #fff);
                        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                      "
                    >
                      <div style="display: flex; justify-content: center">
                        <img
                          :src="c.imagePath"
                          style="max-width: 120px; height: 80px"
                          alt=""
                          @error="handleImageError"
                        />
                      </div>
                      <div class="car-content">
                        <div class="car-top" style="margin-bottom: 0px">
                          <h4>
                            <a
                              style="
                                color: #000;
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.22px;
                                font-family: Poppins;
                              "
                              href="#"
                              >{{ c.name }}</a
                            >
                          </h4>
                          <span
                            style="
                              color: var(--Color-Blue-Blue-05, #207ace);
                              font-size: 11px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              letter-spacing: 0.22px;
                              font-family: Poppins;
                            "
                            >Rp {{ formatPrice(c.rangeHarga) }}</span
                          >
                        </div>
                        <v-row no-gutters>
                          <v-col>
                            <v-chip
                              class="mt-2"
                              closable
                              style="padding: 10px; height: 20px"
                              color="#207ACE"
                              text-color="#207ACE"
                              label
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 300;
                                  font-size: 10px;
                                  font-family: Poppins;
                                "
                                >{{ c.transmisi }} | {{ c.bbm }}</span
                              ></v-chip
                            >
                          </v-col>
                          <v-col
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: flex-end;
                            "
                          >
                            <div>
                              <li></li>
                            </div>
                          </v-col>
                        </v-row>
                        <button
                          @click="handledetaillates(c.slug)"
                          class="btn-detail-res"
                          style="font-family: Poppins"
                        >
                          Detail Mobil
                        </button>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- scroll-top -->
    <a href="#" id="scroll-top"><i class="far fa-arrow-up"></i></a>
    <!-- Tampilan Footer -->
    <foote />
  </div>
</template>

<script>
import navigation from "../components/backup";
import foote from "../components/Footer";
// import VueFaqAccordion from "vue-fsaq-accordion";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"; // Import the CSS file for vue-select
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import _ from "lodash";

export default {
  data() {
    return {
      topCategory: [],
      topView: [],

      activeSlide: 0,
      intervalId: null,
      kategoriData: [],
      selectedMerkData: [],
      selectedTransmisi: [],
      selectedBbm: [],
      searchKey: "", // Mengganti searchQuery menjadi searchKey
      results: [],
      isOpen: false,
      merekOptions: [],
      jenisOptions: [],
      kategoriOptions: [],
      selectedKategori: "",

      carPopular: [],
      carListrik: [],
      carData: [],
      carBrands: [],
      carJenis: [],
      limit: 1000,
      searchKey: "",
      selectedMerek: "",
      selectedTransmisi: "",
      selectedBbm: "",
      selectedSeat: "",
      // selectedListrik: "",
      selectedJenis: [],
      selectedjenis: "",
      selectedSortBy: "",
      selectedSortOrder: "",
      merekOptions: [],
      transmisiOptions: [],
      bbmOptions: [],
      seatOptions: [],
      // listrikOptions: [],
      jenisOptions: [],
      sortByOptions: [],
      sortOrderOptions: [],

      searchResults: [], // Store search results
      carItems: [], // Daftar mobil dari response API
      filteredCarItems: [], // Daftar mobil yang sudah difilter
      uniqueCarTypes: [], // Fungsi Pencarian Berdasarkan Brand
      filteredCarModels: [], // Fungsi Pencarian Berdasarkan Model
      selectedCarType: "", // Opsi yang dipilih untuk Car Brand
      selectedCarModel: "", // Opsi yang dipilih untuk Car Models
      selectedPriceRange: "", // Opsi yang dipilih untuk Price Range
      isLoading: false, // Loading pada filter
      slides: [],
      idcar: "", // ID detail produk
      slug: "",
      carModelSearch: "",
      items: [
        {
          title: "Apa itu Jaja Auto?",
          content:
            "Jaja Auto merupakan layanan berlangganan mobil pertama di Indonesia dengan fasilitas Full Service yang memberikan solusi bebas ribet dalam berkendara baik untuk kebutuhan pribadi maupun perusahaan.",
          open: false,
        },
        {
          title: "Apa Keuntungan Memakai Jaja Auto?",
          content:
            "Solusi All-in-One, Customer akan mendapatkan fasilitas Full Service (Service berkala, Perpanjang STNK, Asuransi, Pergantian aki dan Ban) selama masa berlangganan. Customer memiliki pilihan untuk mengganti mobil dengan versi / model baru saat masa berlangganan habis",
          open: false,
        },
        {
          title: "Apakah Layanan Jaja Auto Tersedia di Indonesia?",
          content:
            "Layanan Kinto untuk saat ini baru tersedia pada wilayah Jabodetabek, Bandung, Surabaya dan akan berkembang ke kota-kota lain nantinya.",
          open: false,
        },
        {
          title: "Apa Model Dan Warna Yang Bisa Dipilih Untuk Mobil Jaja Auto?",
          content:
            "Customer dapat bebas memilih model maupun warna dari kendaraannya sesuai yang tersedia di website Jaja Auto.",
          open: false,
        },
        {
          title:
            "Apakah Mobil Jaja Auto Dapat Dimodifikasi Sesuai Kemauan Customer?",
          content:
            "Customer dapat menghubungi Hotline Kinto untuk informasi lebih lanjut mengenai modifikasi mobil tersebut.",
          open: false,
        },
      ],
    };
  },
  created() {
    this.startAutoSlide();
    this.fetchCarBrands();
    this.fetchCarJenis();
    this.fetchSearchOptions();
    this.fetchDataProduk();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
  computed: {
    jenissOptions() {
      return [
        { label: "Passenger Car", value: "Passenger Car" },
        { label: "Commarcial Car", value: "Commarcial Car" },
        { label: "Luxury Car", value: "Luxury Car" },
        // Add more static options as needed
      ];
    },
    filteredCarData() {
      return this.carData.filter((car) =>
        car.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    filteredCarPopular() {
      return this.carPopular.filter((car) =>
        car.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    filteredCarTypes() {
      // Return filtered car types based on the search input
      const searchTerm = this.selectedCarType.toLowerCase();
      return this.uniqueCarTypes.filter((c) =>
        c.toLowerCase().includes(searchTerm)
      );
    },
    uniqueCarTypes() {
      return Array.from(new Set(this.carItems.map((item) => item.brand)));
    },
    filteredCarModels() {
      if (!this.selectedCarType) return [];
      return this.carItems
        .filter((car) => car.brand === this.selectedCarType)
        .map((car) => car.model);
    },
    // Fungsi Ukuran Banner
    calculateHeight() {
      return `${(9 / 27) * this.$vuetify.breakpoint.width}px`;
    },
    // Fungsi Skeleton Pada Card Produk
    skeletonCount() {
      return this.limit;
    },
  },
  mounted() {
    // this.isLoggedIn = localStorage.getItem("token"); //Fungsi Halaman ini Harus Login
    // this.fetchKategoriData();
    // this.fetchPopularCars();
    // this.fetchLatestCars();
    // this.fetchListrikCars();
    // this.fetchSlider();
    // this.fetchCarItems(); //Fungsi Produk
    this.handledetail(slug); //Fungsi Memanggil ID detail produk

    const searchInput = document.querySelector('input[type="text"]');
    searchInput.addEventListener("input", this.filterCars); //Fungsi Pencarian berdasarkan keyword
  },
  methods: {
    fetchDataProduk() {
      axios
        .get("https://staging-api.jaja.id/dashboard/get-top-product")
        .then((response) => {
          this.topCategory = response.data.data[0].top_category;
          this.topView = response.data.data[0].top_view;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    // Fungsi debounce untuk menunda pemanggilan permintaan HTTP
    debouncedFetchResults: _.debounce(function () {
      if (this.searchKey.trim() === "") {
        // Jika kueri pencarian kosong, tutup kotak saran
        this.isOpen = false;
        return;
      }

      axios
        .get(
          `https://elasticsearch.masterdiskon.com/jaja-auto/autocomplete-product?q=${this.searchKey}`
        )
        .then((response) => {
          this.results = response.data.data;
          this.isOpen = true;
        })
        .catch((error) => {
          console.error("Error fetching results:", error);
        });
    }, 500), // Setel penundaan debounce menjadi 500 milidetik atau sesuaikan sesuai kebutuhan

    // Panggil debouncedFetchResults saat pengguna berhenti mengetik
    handleInput: function () {
      this.debouncedFetchResults();
    },

    showText(index) {
      this.hoverIndex = index;
    },
    hideText(index) {
      this.hoverIndex = null;
    },
    goToTestDriveForm() {
      this.$router.push({ path: "/testDriveForm" });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.activeSlide = (this.activeSlide + 1) % this.carBrands.length;
      }, 3000); // Adjust the interval time (in milliseconds) as needed
    },
    stopAutoSlide() {
      clearInterval(this.intervalId);
    },
    selectAndSearchBrand(brand) {
      this.selectedMerek = [
        {
          value: brand.nama_merek,
          label: brand.nama_merek,
          slug: brand.slug_merek,
        },
      ];
      this.searchCars();
    },

    selectKategori(kategori) {
      console.log("Kategori yang Dipilih:", kategori);
      this.selectedMerkData = kategori.merek;
      console.log("Data Merk yang Diatur:", this.selectedMerkData);
    },
    // async fetchKategoriData() {
    //   const apiUrl = "https://api.jaja.id/jauto/produk/get_kategori";
    //   const headers = {
    //     Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
    //   };

    //   try {
    //     const response = await fetch(apiUrl, { headers });
    //     const data = await response.json();

    //     if (data.success) {
    //       this.kategoriData = data.data;
    //     } else {
    //       console.error("Gagal mengambil data kategori dari API");
    //     }
    //   } catch (error) {
    //     console.error(
    //       "Terjadi kesalahan dalam mengambil data kategori:",
    //       error
    //     );
    //   }
    // },
    async fetchSlider() {
      try {
        const response = await axios.get(
          "https://api.jaja.id/jauto/produk/get_slider",
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            },
          }
        );

        if (response.data.success) {
          this.slides = response.data.data.map((item) => ({
            url_slider: item.url_slider,
            alt: item.id_slider, // You can use a different property for alt text
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    performSearch() {
      this.isLoading = true;

      // Fetch data based on the search term for both carData and carPopular
      this.fetchLatestCars(this.searchTerm);
      this.fetchPopularCars(this.searchTerm);
    },
    fetchLatestCars() {
      axios
        .get("https://api.jaja.id/jauto/produk/get_latest", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          this.carData = response.data.data;
        })
        .catch((error) => {
          console.error("Failed to fetch latest cars data:", error);
        });
    },
    fetchPopularCars() {
      axios
        .get("https://api.jaja.id/jauto/produk/get_popular", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          this.carPopular = response.data.data;
        })
        .catch((error) => {
          console.error("Failed to fetch popular cars data:", error);
        });
    },
    fetchListrikCars() {
      axios
        .get("https://api.jaja.id/jauto/produk/get_listrik", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          this.carListrik = response.data.data;
        })
        .catch((error) => {
          console.error("Failed to fetch popular cars data:", error);
        });
    },
    //Filter berdasarkan merk pada logo brand
    selectBrand(brand) {
      this.selectedMerek = [brand.slug_merek];
      this.searchCars();
    },

    async selectType(type) {
      this.selectedJenis = [type.name];
      this.searchCars();
    },

    async searchCarsAndUpdateURL() {
      await localStorage.setItem(
        "selectedMerek",
        JSON.stringify(this.selectedMerek)
      );
      await localStorage.setItem(
        "selectedTransmisi",
        JSON.stringify(this.selectedTransmisi)
      );
      await localStorage.setItem(
        "selectedBbm",
        JSON.stringify(this.selectedBbm)
      );
      await localStorage.setItem(
        "selectedJenis",
        JSON.stringify(this.selectedJenis)
      );

      const params = {
        brand: this.selectedMerek
          ? this.selectedMerek.map((item) => item.value).join(",")
          : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.map((item) => item.value).join(",")
          : "",
        bbm: this.selectedBbm
          ? this.selectedBbm.map((item) => item.value).join(",")
          : "",
        seat: this.selectedSeat
          ? this.selectedSeat.map((item) => item.value).join(",")
          : "",
        // listrik: this.selectedListrik
        //   ? this.selectedListrik.map((item) => item.value).join(",")
        //   : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy.value : "",
        sortPrice: this.selectedSortOrder ? this.selectedSortOrder.value : "",
        jenis: this.selectedJenis
          ? this.selectedJenis.map((item) => item.label).join(",")
          : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.map((item) => item.label).join(",")
          : "",

        search: this.searchKey,
        // slug_merek: this.selectedMerek && this.selectedMerek.length > 0 ? this.selectedMerek[0].slug : "",

        // jenis_name: this.selectedJenis ? this.selectedJenis[0].value : "",
      };

      this.$router.replace({
        path: "/search",
        query: params,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
      await this.searchCars();
    },
    async searchCars() {
      const params = {
        brand: this.selectedMerek ? this.selectedMerek.join(",") : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.join(",")
          : "",
        bbm: this.selectedBbm ? this.selectedBbm.join(",") : "",
        seat: this.selectedSeat ? this.selectedSeat : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy : "",
        sort_order: this.selectedSortOrder ? this.selectedSortOrder : "",
        jenis_name: this.selectedJenis ? this.selectedJenis.join(",") : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.join(",")
          : "",
        search: this.searchKey,
      };

      const apiUrl = `https://staging-api.jaja.id/product/get-product?${new URLSearchParams(
        params
      )}`;
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        console.log("Fetching from API:", apiUrl);
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        console.log("API Response:", data);

        if (data.data) {
          this.searchResults = data.data;
          localStorage.setItem("searchResults", JSON.stringify(data.data));
          localStorage.setItem(
            "selectedMerek",
            JSON.stringify(this.selectedMerek)
          );
          localStorage.setItem(
            "selectedTransmisi",
            JSON.stringify(this.selectedTransmisi)
          );
          localStorage.setItem("selectedBbm", JSON.stringify(this.selectedBbm));

          const currentParams = this.$route.query;
          const sameParams = Object.keys(params).every(
            (key) => params[key] === currentParams[key]
          );

          if (!sameParams) {
            this.$router.push({
              path: "/search",
              query: params,
            });

            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        } else {
          console.error("Unexpected API response structure:", data);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },
    async fetchCarJenis() {
      try {
        const response = await axios.get(
          "https://staging-api.jaja.id/jenis/get-jenis?page=0&limit=0&keyword="
        );

        // Assuming the response structure matches the provided result
        this.carJenis = response.data.data.data;
        this.totalCarJenis = this.carJenis.length; // Calculate total car jenis
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching car jenis:", error);
        // Handle error appropriately
      }
    },
    async fetchCarBrands() {
      try {
        const response = await axios.get(
          "https://staging-api.jaja.id/merek/get-merek?page=0&limit=0&keyword=",
          {}
        );

        // Assuming the response structure matches the provided result
        this.carBrands = response.data.data.data;
        this.totalCarBrands = this.carBrands.length;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching car brands:", error);
        // Handle error appropriately
      }
    },
    toggleAccordion(index) {
      this.items[index].open = !this.items[index].open;
    },
    filterCars() {
      const searchTerm = this.carModelSearch.toLowerCase();
      this.items.forEach((item) => {
        if (
          item.title.toLowerCase().includes(searchTerm) ||
          item.content.toLowerCase().includes(searchTerm)
        ) {
          item.open = true; // Tampilkan item jika cocok dengan pencarian
        } else {
          item.open = false; // Sembunyikan item jika tidak cocok dengan pencarian
        }
      });
    },

    async fetchSearchOptions() {
      const apiUrl = "https://staging-api.jaja.id/product/get-select-option";
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.status.code === 200) {
          this.kategoriOptions = data.data.kategori_option;
          this.merekOptions = data.data.merek_option;
          this.transmisiOptions = data.data.transmisi_option;
          this.bbmOptions = data.data.bbm_option;
          this.seatOptions = data.data.seat_option;
          this.jenisOptions = data.data.jenis_option;
          this.sortByOptions = data.data.sort_by;
          this.sortOrderOptions = data.data.price_option;
        } else {
          console.error("Gagal mengambil data dari API");
        }
      } catch (error) {
        console.error("Terjadi kesalahan dalam mengambil data:", error);
      }
    },
    onDropdownOpen() {
      this.$nextTick(() => {
        const dropdown = document.querySelector(".v-select .vue-dropdown");
        if (dropdown) {
          dropdown.style.maxHeight = "200px"; // Set the maximum height of the dropdown
          dropdown.style.overflowY = "auto"; // Add scrollbar if options overflow
        }
      });
    },
    onDropdownOpenModel() {
      this.$nextTick(() => {
        const dropdown = document.querySelector(
          ".v-select .vue-dropdown .dropdown-content"
        );
        if (dropdown) {
          dropdown.style.maxHeight = "200px"; // Set the maximum height of the dropdown
          dropdown.style.overflowY = "auto"; // Add scrollbar if options overflow
        }
      });
    },
    scrollToTop() {
      // Scroll to the top of the dropdown when it opens
      this.$refs.merkSelect.$el.querySelector(".v-select-list").scrollTop = 0;
      this.$refs.modelSelect.$el.querySelector(".v-select-list").scrollTop = 0;
    },
    // Fungsi Jika Image Eror
    handleImageError(event) {
      event.target.src = require("../assets/img/kosong/1.png");
    },
    // Fungsi Produk Jaja Auto
    fetchCarItems() {
      this.isLoading = true;
      axios
        .get("https://api.jaja.id/produk/get", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          console.log("API Response:", response.data.data); // Add this line to check the API response

          const uniqueCarItems = this.removeDuplicateItems(response.data.data);
          this.carItems = uniqueCarItems;
          this.filteredCarItems = [...this.carItems];
          this.updateUniqueCarTypes();

          // Calculate brand and model counts
          const brandSet = new Set();
          const modelSet = new Set();
          this.carItems.forEach((item) => {
            brandSet.add(item.brand);
            modelSet.add(item.model);
          });

          console.log("Brand Set:", brandSet); // Add this line to check the brandSet

          const brandCount = brandSet.size;
          const modelCount = modelSet.size;

          const brandCounter = document.getElementById("brandCounter");
          const modelCounter = document.getElementById("modelCounter");
          brandCounter.textContent = brandCount;
          modelCounter.textContent = modelCount;

          console.log("Ini responsenya:", response.data.data);
          console.log("Ini isinya:", this.carItems);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    // Fungsi Hapus Data Duplikat
    removeDuplicateItems(items) {
      const uniqueItems = [];
      const seen = new Set();

      for (let item of items) {
        const key = `${item.brand}_${item.model}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniqueItems.push(item);
        }
      }

      return uniqueItems;
    },
    // Fungsi Pencarian Berdasarkan Brand
    updateUniqueCarTypes() {
      this.uniqueCarTypes = Array.from(
        new Set(this.carItems.map((item) => item.brand))
      );
    },
    // Fungsi Pencarian Berdasarkan Model
    updateCarModels() {
      this.filteredCarModels = this.carItems
        .filter((car) => car.brand === this.selectedCarType)
        .map((car) => car.model);
      this.selectedCarModel = ""; // Reset the selectedCarModel when the brand is changed
    },
    onSearchCarType(search, loading) {
      loading(true);
      // Simulate async search (replace with your actual search logic)
      setTimeout(() => {
        this.searchResults = this.uniqueCarTypes.filter((carType) =>
          carType.toLowerCase().includes(search.toLowerCase())
        );
        loading(false);
      }, 500);
    },
    // Fungsi Filter
    filterCars() {
      const uniqueCarTypes = new Set();

      this.filteredCarItems = this.carItems.filter((car) => {
        const isBrandMatched =
          this.selectedCarType === "" || car.brand === this.selectedCarType;
        const isModelMatched =
          this.selectedCarModel === "" || car.model === this.selectedCarModel;
        const isPriceRangeMatched =
          this.selectedPriceRange === "" ||
          car.code === this.selectedPriceRange;
        const isSearchMatched =
          !this.carModelSearch ||
          car.model.toLowerCase().includes(this.carModelSearch.toLowerCase());

        if (
          isBrandMatched &&
          isModelMatched &&
          isPriceRangeMatched &&
          isSearchMatched
        ) {
          if (!uniqueCarTypes.has(car.brand)) {
            uniqueCarTypes.add(car.brand);
          }
          return true;
        }

        return false;
      });

      this.$nextTick(() => {
        // Scroll ke elemen dengan ref "resultsContainer"
        const resultsContainer = this.$refs.resultsContainer;
        if (resultsContainer) {
          const topOffset = resultsContainer.offsetTop;
          window.scrollTo({ top: topOffset, behavior: "smooth" });
        }
      });
      // ...
    },
    // Fungsi Titik Pada Harga
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },
    handledetaillates(carslug) {
      this.slug = carslug;
      console.log("slugss", this.slug);
      // Simpan c.slug di local storage
      localStorage.setItem("slug", carslug);

      // Redirect ke halaman "detaillates" dengan slug dalam URL
      this.$router.push(`/detailview/${carslug}`);
      localStorage.removeItem("slug");
      console.log(`berhasil di remove`);
    },
    // Fungsi Mengambil ID detail
    handledetail(carsid) {
      this.idcar = carsid;
      console.log("idcars", this.idcar);
      localStorage.setItem("idcar", this.idcar);
      this.$router.push("/detail");
    },
  },

  components: {
    navigation,
    foote,
    // VueFaqAccordion,
    vSelect,
    "el-select": ElementUI.Select,
    "el-option": ElementUI.Option,
  },
};
</script>

<style scoped>
/* CSS untuk merapikan tampilan hasil pencarian */
.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: white;
  width: 400px;
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  height: 200px; /* Tambahkan ketinggian untuk membuat scroll jika lebih dari tinggi maksimum */
  overflow-y: auto; /* Tambahkan scrollbar vertikal jika konten melebihi ketinggian maksimum */
}
/* CSS untuk scrollbar */
.search-results::-webkit-scrollbar {
  width: 8px; /* Lebar scrollbar */
}

.search-results::-webkit-scrollbar-track {
  background: #f1f1f1; /* Warna latar belakang dari track scrollbar */
}

.search-results::-webkit-scrollbar-thumb {
  background: #cbcbcb; /* Warna dari thumb scrollbar */
  border-radius: 4px; /* Rounding dari thumb scrollbar */
}

.search-results::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb; /* Warna thumb scrollbar saat dihover */
}

.search-results li {
  padding: 10px; /* Tambahkan padding untuk ruang di sekitar setiap item */
  cursor: pointer; /* Tambahkan pointer cursor saat item dihover */
  transition: background-color 0.3s; /* Efek transisi saat item dihover */
}

.search-results li:hover {
  background-color: #f0f0f0; /* Warna latar belakang saat item dihover */
}

/* Gaya teks */
.search-results li span {
  font-weight: bold; /* Teks tebal untuk nama dan merek */
}

.search-results li .brand {
  color: #666; /* Warna teks untuk merek */
}

.search-results li .bbm {
  color: #999; /* Warna teks untuk BBM */
}

.search-resultss {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* position: absolute; */
  background-color: white;
  /* width: 400px; */
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  height: 200px; /* Tambahkan ketinggian untuk membuat scroll jika lebih dari tinggi maksimum */
  overflow-y: auto; /* Tambahkan scrollbar vertikal jika konten melebihi ketinggian maksimum */
}
/* CSS untuk scrollbar */
.search-resultss::-webkit-scrollbar {
  width: 8px; /* Lebar scrollbar */
}

.search-resultss::-webkit-scrollbar-track {
  background: #f1f1f1; /* Warna latar belakang dari track scrollbar */
}

.search-resultss::-webkit-scrollbar-thumb {
  background: #cbcbcb; /* Warna dari thumb scrollbar */
  border-radius: 4px; /* Rounding dari thumb scrollbar */
}

.search-resultss::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb; /* Warna thumb scrollbar saat dihover */
}

.search-resultss li {
  padding: 10px; /* Tambahkan padding untuk ruang di sekitar setiap item */
  cursor: pointer; /* Tambahkan pointer cursor saat item dihover */
  transition: background-color 0.3s; /* Efek transisi saat item dihover */
}

.search-resultss li:hover {
  background-color: #f0f0f0; /* Warna latar belakang saat item dihover */
}

/* Gaya teks */
.search-resultss li span {
  font-weight: bold; /* Teks tebal untuk nama dan merek */
}

.search-resultss li .brand {
  color: #666; /* Warna teks untuk merek */
}

.search-resultss li .bbm {
  color: #999; /* Warna teks untuk BBM */
}
.image-jenis {
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* width: 100%; */
}

.overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.show-overlay {
  opacity: 1;
}

.image-jenis:hover .overlay {
  opacity: 1;
}

.image-jenis img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-jenis:hover img {
  transform: scale(1.1);
}
.brand-container {
  position: relative;
  box-shadow: 20px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  height: auto;
  margin-bottom: 25px;
  margin-top: 25px;
  transition: var(--transition);
  display: flex;
  justify-content: center; /* Memposisikan konten di tengah horizontal */
  align-items: center; /* Memposisikan konten di tengah vertikal */
  padiing-left: 0px;
  padding-right: 0px;
}

.brand-container > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.brand-container img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 440px) {
  .contoh-search {
    transform: translateY(-60px);
  }
}
@media only screen and (min-width: 1275px) {
  .contoh-search {
    transform: translateY(-155px);
  }
}
.txt-drive {
  color: var(--Color-Neutral-Neutral-00, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 20px;
}
.txt-drive-mobile {
  color: var(--Color-Neutral-Neutral-00, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 20px;
}
.container1 {
  position: relative;
}

.image-containerr {
  position: relative;
  display: inline-block;
}
.banner-img {
  background-image: url("../assets/img/homepage/banner-staatis.png");
  width: 100%;
  height: 1080px;
}
.banner-promo {
  background-image: url("../assets/img/homepage/banner-p.jpg");
  height: 560px;
  display: flex;
  align-items: center;
}
.centered-buttonn {
  height: 50px;

  z-index: 1; /* Make sure the button is above the image */

  border: 2px solid var(--Color-Neutral-Neutral-00, #fff);
}
.centered-buttonn-mobile {
  height: 25px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Make sure the button is above the image */

  border: 2px solid var(--Color-Neutral-Neutral-00, #fff);
}
.find-buttonn {
  height: 50px;

  z-index: 1; /* Make sure the button is above the image */

  border: 2px solid var(--Color-Neutral-Neutral-00, #fff);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 50px;
  color: white;
}

.popup img {
  max-width: 100%;
  max-height: 80%;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Atur lebar sesuai kebutuhan */
  height: 20px; /* Atur tinggi sesuai kebutuhan */
}

.image-container img {
  max-width: 100px;
  max-height: 70px;
  object-fit: cover;
  border-radius: 0;
}

@media screen and (max-width: 576px) {
  .counter-box {
    font-size: 14px; /* Atur ukuran font lebih kecil */
  }
}
.car-gambar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* This centers the content horizontally */
  height: 100%;
  /* Assuming you want the container to take up the full height */
}

.vs--searchable .vs__dropdown-toggle {
  height: 100px !important;
}

.custom-dropdown .dropdown-menu {
  max-height: 60px;
  /* Sesuaikan tinggi maksimal yang Anda inginkan */
  overflow-y: auto;
}

.col-lg-4 .form-group .v-select .dropdown-toggle::after {
  transform: scaleY(0.5);
  /* Sesuaikan skala sesuai keinginan Anda */
}

.v-select-list {
  max-height: 200px;
  /* Set the maximum height for the dropdown */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Style for "no options" message */
.no-options {
  padding: 8px;
}

.custom-select {
  height: 200px !important;
  max-height: none !important;
}
.btn-detail-res {
  margin-top: 10px;
  font-size: 10px;
  width: 100%;
  margin-left: -1px;
  height: auto;
  color: #207ace;
  padding: 5px 8px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  background-color: white;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  border: 1px solid #207ace;
}

.btn-detail-res:hover {
  background-color: #207ace;
  /* Warna latar belakang berubah menjadi biru saat dihover */
  color: white;
  /* Warna teks berubah menjadi putih saat dihover */
}
.btn-detail {
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  margin-left: -1px;
  height: auto;
  color: #207ace;
  padding: 7px 10px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 7px;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  border: 2px solid #207ace;
  /* Menambahkan border dengan warna biru yang sama */
}

.btn-detail:hover {
  background-color: #207ace;
  /* Warna latar belakang berubah menjadi biru saat dihover */
  color: white;
  /* Warna teks berubah menjadi putih saat dihover */
}

.custom-chip {
  background-color: white;
  color: black;
  border: 1px solid grey;
  outline: 2px solid grey;
  /* Menambahkan outline dengan ketebalan 2px */
  outline-offset: -2px;
  /* Menyesuaikan offset outline agar tidak mengubah ukuran chip */
}

.custom-group {
  border: 1px solid grey;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 4px;
  border-radius: 4px;
}

.mySlider {
  display: none;
}

.flickity-page-dots {
  display: none !important;
}

.carousel-cell {
  width: 200px;
  height: auto;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #ccc;
}

.background {
  /* width: 100%;
height: 300px; */
  background-image: url("../assets/img/homepage/3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }

        30% {
          transform: translateX(-5px);
        }

        50% {
          transform: translateX(5px);
        }

        70% {
          transform: translateX(-2px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style scoped>
input::placeholder {
  color: #878787;
  /* Change this to the desired color */
}

@media (max-width: 600px) {
  .banner {
    width: 100%;
    height: auto;
    margin-top: 40%;
  }

  .img-card {
    width: 150px;
    height: 100px;
    object-fit: contain;
  }

  .color-black {
    background-color: black;
    color: white;
    border: 1px solid grey;
    outline: 2px solid black;
    outline-offset: -2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .color-white {
    background-color: white;
    color: white;
    border: 1px solid grey;
    outline: 2px solid black;
    outline-offset: -2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .color-gray {
    background-color: gray;
    color: white;
    border: 1px solid grey;
    outline: 2px solid gray;
    outline-offset: -2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .banner {
    width: 100%;
    height: auto;
  }

  .img-card {
    max-width: 250px;
    height: 170px;
    object-fit: contain;
  }
}

@media (min-width: 961px) {
  .banner {
    width: 100%;
    height: auto;
  }

  .img-card {
    max-width: 250px;
    height: 170px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    width: 100%;
    height: auto;
    margin-top: 40%;
  }

  .img-card {
    max-width: 250px;
    height: 170px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    width: 100%;
    height: auto;
    margin-top: 15%;
  }

  .img-card {
    max-width: 250px;
    height: 170px;
    object-fit: contain;
  }
}

section {
  position: relative;
}

#hero {
  z-index: 1;
}

.btn-find {
  font-size: 16px;
  color: black;
  padding: 5px 5px;
  transition: all 0.5s;
  margin-top: -2px;
  text-transform: capitalize;
  height: 40px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: #fbab44;
  box-shadow: var(--box-shadow);
  z-index: 1;
}

.btn-find:hover {
  background: #1f1c1c;
  color: white;
}

.apaini {
  padding-left: 100px;
  padding-right: 100px;
}

.haha {
  padding-left: 215px;
  padding-top: 230px;
}
</style>

<style scoped>
@import "../assets/css/all-fontawesome.min.css";
@import "../assets/css/animate.min.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/flex-slider.min.css";
@import "../assets/css/jquery-ui.min.css";
@import "../assets/css/magnific-popup.min.css";
@import "../assets/css/nice-select.min.css";
@import "../assets/css/owl.carousel.min.css";
@import "../assets/css/style.css";
</style>
