<template>
  <div class="home-3">
    <!-- header area -->

    <div>
      <section class="py-5 text-white" style="background-color: #207ACE">
        <div class="container d-flex justify-content-between">
          <h3 style="color: white">Kebijakan Privasi</h3>
          <img
            src="http://localhost:8081/img/jato.8e78e5e3.png"
            style="width: 150px"
          />
        </div>
      </section>
    </div>
    <br />
    <div class="container">
      <h5 style="color: black">1. Informasi yang Kami Kumpulkan:</h5>
      <p>
        Saat Anda mengunjungi
        <a style="color: black" href="https://auto.jaja.id/"
          >https://auto.jaja.id/</a
        >, kami secara otomatis mengumpulkan informasi perangkat Anda, termasuk:
      </p>
      <ul>
        <li>- Browser web yang digunakan</li>
        <li>- Alamat IP</li>
        <li>- Zona waktu</li>
        <li>- Cookie yang terinstal di perangkat Anda</li>
      </ul>
      <br />
      <p>Selain itu, kami mengumpulkan informasi tentang:</p>
      <ul>
        <li>- Halaman web atau produk yang Anda lihat</li>
        <li>
          - Website atau istilah pencarian yang mengarahkan Anda ke Website
        </li>
        <li>- Interaksi Anda dengan Website</li>
      </ul>
      <p>
        Kami menyebutnya "Informasi Perangkat." Kami juga dapat mengumpulkan
        data pribadi yang Anda berikan, seperti Nama, Alamat, informasi
        pembayaran, dll., selama pendaftaran.
      </p>

      <h5 style="color: black">2. Tujuan Pengumpulan Data:</h5>
      <p>
        Kami menjaga keamanan data pelanggan sebagai prioritas utama. Kami hanya
        memproses data yang diperlukan untuk menjalankan website kami. Informasi
        Perangkat digunakan untuk mengidentifikasi penyalahgunaan dan statistik
        penggunaan website. Informasi ini tidak digabungkan untuk
        mengidentifikasi pengguna tertentu.
      </p>

      <h5 style="color: black">3. Hak Anda:</h5>
      <p>
        Jika Anda adalah warga Eropa, Anda memiliki hak berikut terkait data
        pribadi Anda:
      </p>
      <ul>
        <li>- Hak penjelasan</li>
        <li>- Hak akses</li>
        <li>- Hak perbaikan</li>
        <li>- Hak penghapusan</li>
        <li>- Hak pembatasan pemrosesan</li>
        <li>- Hak portabilitas data</li>
        <li>- Hak penolakan</li>
        <li>- Hak terkait pengambilan keputusan otomatis</li>
      </ul>
      <p>Jika ingin menggunakan hak-hak ini, hubungi kami.</p>

      <h5 style="color: black">4. Pengolahan Data di Luar Eropa:</h5>
      <p>
        Data Anda mungkin dipindahkan ke luar Eropa, termasuk Kanada dan Amerika
        Serikat.
      </p>

      <h5 style="color: black">5. Tautan ke Website Lain:</h5>
      <p>
        Website kami dapat memiliki tautan ke website lain yang tidak kami
        kendalikan. Kami tidak bertanggung jawab atas praktik privasi mereka.
        Harap waspada saat meninggalkan website kami.
      </p>

      <h5 style="color: black">6. Keamanan Informasi:</h5>
      <p>
        Kami menjaga keamanan data Anda. Data disimpan dalam lingkungan
        terkendali, aman, dan terlindungi. Kami memiliki pengamanan
        administratif, teknis, dan fisik yang wajar.
      </p>

      <h5 style="color: black">7. Pengungkapan Hukum:</h5>
      <p>
        Kami akan mengungkap informasi jika diwajibkan atau diizinkan oleh
        hukum, atau jika kami percaya bahwa pengungkapan diperlukan untuk
        melindungi hak kami atau keselamatan Anda.
      </p>

      <h5 style="color: black">8. Informasi Kontak:</h5>
      <p>
        Jika ingin menghubungi kami terkait Kebijakan Privasi atau data pribadi
        Anda, kirim email ke
        <a href="mailto:tasyakhaerani66@gmail.com">tasyakhaerani66@gmail.com</a
        >.
      </p>
    </div>
  </div>
</template
>
<script scope>
import navigation from "../components/backup.vue";
import Flickity from "flickity";
import "flickity/css/flickity.css";
import foote from "../components/Footer";

export default {
  name: "About",
  methods: {},
  components: {
    navigation,
    foote,
  },
};
</script>
