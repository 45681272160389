<template>
  <div style="background-color: #f8f8f8">
    <!-- Tampilan Navbar -->
    <navigation />
    <div style="padding: 5%; padding-top: 12%">
      <div>
        <div
          style="
            padding: 40px 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 60px;
            border-radius: 20px;
            background: var(--color-neutral-neutral-00, #fff);
          "
        >
          <div>
            <span
              style="
                color: var(--color-blue-blue-06, #207ace);
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.32px;
                font-family: Poppins;
              "
              >Detail Pengajuan Anda</span
            >
            <br />
            <br />
            <div class="mt-5">
              <v-row>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Merk</p>
                  <span class="title-angsuran">{{
                    simulasiPertama.name_jenis
                  }}</span>
                </v-col>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Model</p>
                  <span class="title-angsuran">{{
                    simulasiPertama.name_produk
                  }}</span>
                </v-col>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Tipe</p>
                  <span class="title-angsuran">{{
                    simulasiPertama.name_produk_grades
                  }}</span>
                </v-col>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Jenis</p>
                  <span class="title-angsuran">{{
                    simulasiPertama.name_jenis
                  }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Harga Unit</p>
                  <span class="title-angsuran">{{
                    formatPriceAsRupiah(simulasiPertama.rangeHarga)
                  }}</span>
                </v-col>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">DP</p>
                  <span class="title-angsuran">{{
                    formatPriceAsRupiah(simulasiData[0].dp)
                  }}</span>
                </v-col>

                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Wilayah</p>
                  <span class="title-angsuran">{{ selectedWilayahText }}</span>
                  <!-- <p class="title-judul-angsuran">Harga Interior</p>
                  <span class="title-angsuran">{{
                    formatPriceAsRupiah(simulasiPertama.interior_price)
                  }}</span> -->
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Harga Total</p>
                  <span class="title-angsuran">
                    {{ formatPriceAsRupiah(simulasiPertama.grandtotal) }}
                  </span>
                </v-col>

                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Mitra</p>
                  <span class="title-angsuran">{{ selectedMitraText }}</span>
                </v-col>
                <v-col xs="12" sm="6" md="3" class="custom-col">
                  <p class="title-judul-angsuran">Wilayah</p>
                  <span class="title-angsuran">{{ selectedWilayahText }}</span>
                </v-col>
              </v-row> -->
            </div>
            <br />
            <div class="mt-5">
              <span
                style="
                  color: var(--color-blue-blue-06, #207ace);
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.48px;
                  font-family: Poppins;
                "
              >
                Pilih Tenor yang sesuai dengan budget anda dan lengkapi data
                pengajuan
              </span>
            </div>
          </div>
          <div class="d-lg-none" style="padding: 10px">
            <div class="mt-5 row">
              <div
                class="col"
                style="
                  background-color: #ebf8ff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="justify-content-center">
                  <span
                    style="
                      color: var(--Color-Blue-Blue-06, #0f5392);
                      font-size: 8px;
                      font-style: normal;
                      font-weight: 500;
                      font-family: Poppins;
                      line-height: normal;
                      justify-content: center;
                      letter-spacing: 0.16px;
                    "
                    >Tenor</span
                  >
                </div>
              </div>
              <div
                class="col"
                style="
                  background-color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="justify-content-center">
                  <span
                    style="
                      color: var(--Color-Blue-Blue-06, #0f5392);
                      font-size: 8px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      justify-content: center;
                      letter-spacing: 0.16px;
                      font-family: Poppins;
                    "
                    >Total</span
                  >
                </div>
              </div>
              <div
                class="col"
                style="
                  background-color: #ebf8ff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="justify-content-center">
                  <span
                    style="
                      color: var(--Color-Blue-Blue-06, #0f5392);
                      font-size: 8px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      justify-content: center;
                      letter-spacing: 0.16px;
                      font-family: Poppins;
                    "
                    >Suku Bunga
                  </span>
                </div>
              </div>
              <div
                class="col"
                style="
                  background-color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="justify-content-center">
                  <span
                    style="
                      color: var(--Color-Blue-Blue-06, #0f5392);
                      font-size: 8px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      justify-content: center;
                      letter-spacing: 0.16px;
                      font-family: Poppins;
                    "
                    >Angsuran/bln</span
                  >
                </div>
              </div>
              <div
                class="col"
                style="
                  background-color: #ebf8ff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="justify-content-center">
                  <span
                    style="
                      color: var(--Color-Blue-Blue-06, #0f5392);
                      font-size: 8px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      justify-content: center;
                      letter-spacing: 0.16px;
                      font-family: Poppins;
                    "
                    >Aksi
                  </span>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in simulasiData" :key="index">
              <!-- <div>{{ item }}</div> -->
              <div
                style="
                  border: 0.5px solid var(--Color-Yellow-Yellow-02, #fdd5a1);
                  padding: 5px;
                  margin-top: 100px;
                "
                class="mt-5 row"
              >
                <div class="col">
                  <span class="text-ang-res">
                    {{ item.tenorTahun }} Tahun
                  </span>
                </div>
                <div class="col">
                  <span class="text-ang-res">
                    {{ formatPrice(item.angsuranPertama) }}</span
                  >
                </div>
                <div class="col">
                  <span class="text-ang-res">{{ item.bunga }}%</span>
                </div>
                <div class="col">
                  <span class="text-ang-res">
                    {{
                      formatPrice(item.angsuran_bulanan).toLocaleString(
                        "id-ID",
                        {
                          style: "currency",
                          currency: "IDR",
                        }
                      )
                    }}
                  </span>
                </div>
                <div class="col">
                  <button
                    style="
                      border-radius: 2px;
                      background: var(--Color-Yellow-Yellow-Stars, #ffd600);
                      display: flex;
                      width: 100%;
                      padding: 5px;
                      justify-content: center;
                      height: 20px;
                      align-items: center;
                      gap: 10px;
                      flex: 1 0 0;
                    "
                    type="submit"
                    @click="selectTenor(index)"
                  >
                    <span
                      style="
                        color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.16px;
                        font-family: Poppins;
                      "
                      v-if="isLoading"
                    >
                      <i class="fas fa-spinner fa-spin"></i>
                      Loading...
                    </span>
                    <span
                      style="
                        color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.16px;
                        font-family: Poppins;
                      "
                      v-else
                      >Pilih</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-lg-block">
            <v-row class="mt-3">
              <v-col>
                <div class="d-flex flex-column align-items-center">
                  <div class="ca" style="border-radius: 10px 10px 0 0">
                    <span class="text-card">Tenor</span>
                  </div>
                  <div class="cb">
                    <span class="text-card text-center"
                      >Total Pembayaran Pertama</span
                    >
                  </div>
                  <div class="ca">
                    <span class="text-card">Suku Bunga</span>
                  </div>
                  <div class="cb" style="border-radius: 0 0 10px 10px">
                    <span class="text-card text-center">Angsuran/bulan</span>
                  </div>
                </div>
              </v-col>
              <v-col
                v-for="(item, index) in simulasiData"
                :key="index"
                class="mb-3"
              >
                <div class="card-tenor mr-1">
                  <div class="ca1">
                    <span class="ta1"
                      >{{ item.tenorTahun }} Thn /
                      {{ item.tenorBulan }} Bln</span
                    >
                  </div>
                  <div class="ca1">
                    <span class="ta2"
                      >Rp {{ formatPrice(item.angsuranPertama) }}</span
                    >
                  </div>
                  <div class="ca1">
                    <span class="ta1">{{ item.bunga }}%</span>
                  </div>
                  <div class="ca1">
                    <span class="ta2">
                      Rp
                      {{
                        formatPrice(item.angsuran_bulanan).toLocaleString(
                          "id-ID",
                          { style: "currency", currency: "IDR" }
                        )
                      }}
                    </span>
                  </div>
                  <div style="padding: 5px; width: 100%; padding-right: 15px">
                    <button
                      type="submit"
                      @click="selectTenor(index)"
                      class="button"
                      style="font-family: Poppins"
                    >
                      <span v-if="isLoading">
                        <i class="fas fa-spinner fa-spin"></i>
                        Loading...
                      </span>
                      <span v-else>Pilih Tenor</span>
                    </button>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <br />
        </div>
        <br />
        <div
          v-if="showForm"
          style="
            border-radius: 20px;
            background: var(--color-neutral-neutral-00, #fff);
            padding: 40px;
          "
        >
          <div class="d-lg-none">
            <h6 style="color: black" class="car-single-price">
              Detail Pengajuan Anda
            </h6>
            <hr style="border-color: #ffd600; border-width: 2px" />
            <br />
          </div>
          <div class="d-none d-lg-block">
            <p
              ref="scrollToElement"
              style="
                color: black;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.32px;
                font-family: Poppins;
              "
            >
              Form Pengajuan Angsuran Anda
            </p>
            <span
              style="
                color: black;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.27px;
                font-family: Poppins;
              "
              >Mohon diisi dengan sebenar-benarnya</span
            >
            <br />
            <br />
          </div>
          <!-- <span> {{ formatPriceAsRupiah(selectedTenorData) }}</span> -->

          <div v-if="selectedTenorData">
            <div class="row">
              <div class="col">
                <h6 class="car-single-price">Harga Total</h6>
                <v-chip
                  class="ma-2"
                  closable
                  color="#E9F7FF"
                  text-color="#207ACE"
                  label
                  style="font-family: Poppins"
                >
                  {{ formatPriceAsRupiah(simulasiPertama.grandtotal) }}
                </v-chip>
              </div>
              <div class="col">
                <h6 class="d-none d-lg-block car-single-price">
                  Angsuran Pertama
                </h6>
                <h6 class="d-lg-none car-single-price">Pertama</h6>
                <v-chip
                  class="ma-2"
                  closable
                  color="#E9F7FF"
                  text-color="#207ACE"
                  label
                  style="font-family: Poppins"
                >
                  Rp
                  {{
                    formatPrice(
                      selectedTenorData.angsuranPertama
                    ).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })
                  }}
                </v-chip>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h6 class="car-single-price">Tenor</h6>
                <v-chip
                  class="ma-2"
                  closable
                  color="#E9F7FF"
                  text-color="#207ACE"
                  label
                  style="font-family: Poppins"
                >
                  {{ selectedTenorData.tenorTahun }} Thn /
                  {{ selectedTenorData.tenorBulan }} Bln
                </v-chip>
              </div>
              <div class="col">
                <h6 class="d-none d-lg-block car-single-price">
                  Angsuran Bulanan
                </h6>
                <h6 class="d-lg-none car-single-price">Bulanan</h6>
                <v-chip
                  class="ma-2"
                  closable
                  color="#E9F7FF"
                  text-color="#207ACE"
                  label
                  style="font-family: Poppins"
                >
                  Rp
                  {{
                    formatPrice(
                      selectedTenorData.angsuran_bulanan
                    ).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })
                  }}
                </v-chip>
              </div>
            </div>
          </div>
          <br />
          <div class="d-lg-none">
            <h6 style="color: black" class="car-single-price">Form Data</h6>
            <hr style="border-color: #ffd600; border-width: 2px" />
          </div>
          <form class="mt-3" @submit.prevent="submitAngsuran">
            <v-row>
              <v-col cols="12" md="6">
                <div class="form-group">
                  <label style="font-family: Poppins" class="label-form"
                    >Nama Lengkap</label
                  >
                  <input
                    type="text"
                    style="font-family: Poppins"
                    class="mt-3 form-control"
                    v-model="displayedProfile.name"
                    placeholder="Nama Anda"
                    id="inputName"
                    required
                  />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="form-group">
                  <label style="font-family: Poppins" class="label-form"
                    >WhatsApp</label
                  >
                  <div class="input-group mb-3">
                    <div class="mt-3 input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >+62</span
                      >
                    </div>
                    <input
                      type="tel"
                      style="font-family: Poppins"
                      class="mt-3 form-control"
                      v-model="displayedProfile.phoneNumber"
                      placeholder="No Handphone Anda"
                      aria-describedby="basic-addon1"
                      id="inputNumber"
                      required
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
            <br />
            <v-row>
              <v-col cols="12" md="6">
                <div class="form-group">
                  <label style="font-family: Poppins" class="label-form"
                    >Alamat Email</label
                  >
                  <input
                    type="email"
                    style="font-family: Poppins"
                    class="mt-3 form-control"
                    v-model="displayedProfile.email"
                    placeholder="Email Anda"
                    id="inputEmail"
                    required
                  />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="form-group">
                  <label style="font-family: Poppins" class="label-form"
                    >Domisili</label
                  >
                  <input
                    class="mt-3 form-control"
                    style="font-family: Poppins"
                    v-model="displayedProfile.alamat_lengkap"
                    placeholder="Jakarta"
                    id="inputAlamat"
                    required
                  />
                </div>
              </v-col>
            </v-row>
            <br />
            <div>
              <li class="text-ketentuan">
                1. Harga yang tertera belum termasuk dengan asuransi ataupun
                perluasan
              </li>
              <li class="mt-1 text-ketentuan">
                2. Perhitungan simulasi di atas tidak mengikat dan dapat berubah
                sewaktu-waktu
              </li>
              <li class="mt-1 text-ketentuan">
                3. Skema pembayaran Angsuran pertama Dibayarkan di Muka (ADDM)
              </li>

              <li class="mt-1 text-ketentuan">
                4. Total Pembayaran Pertama terdiri atas Down Payment (DP) Nett,
                Biaya Admin
              </li>
              <br />
              <input type="checkbox" v-model="isChecked" required />
              Saya setuju dengan syarat dan ketentuan
            </div>

            <div class="mt-3 form-group">
              <button
                type="submit"
                class="btn-ajukan-angsuran"
                style="font-family: Poppins; height: 50px"
                :disabled="loadingAngsuran"
              >
                {{
                  loadingAngsuran
                    ? "Sedang memproses data..."
                    : "Ajukan Angsuran"
                }}
              </button>
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
    <foote />
  </div>
</template>
<script>
import navigation from "../components/backup";
import foote from "../components/Footer";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "HasilAngsuran",
  components: {
    navigation, //Navbar
    foote, // Footer
  },
  data() {
    return {
      isChecked: false,
      loadingAngsuran: false,
      isLoading: false,
      simulasiData: null,
      simulasiPertama: null,
      carDetail: null,
      selectedMitraText: localStorage.getItem("selectedMitraText") || "",
      selectedWilayahText: localStorage.getItem("selectedWilayahText") || "",
      showForm: false, // Data ini mengendalikan visibilitas form
      selectedTenorData: null, // Data untuk menyimpan informasi tenor yang dipilih
      displayedProfile: {
        name: "",
        phoneNumber: "",
        email: "",
        alamat_lengkap: "",
      },
    };
  },
  mounted() {
    this.simulasiData = JSON.parse(localStorage.getItem("simulasiData"));
    this.simulasiPertama = JSON.parse(localStorage.getItem("simulasiPertama"));
    this.carDetail = JSON.parse(localStorage.getItem("carDetail"));
  },

  methods: {
    formatPriceAsRupiah(priceString) {
      const price = parseInt(priceString, 10); // Konversi string menjadi bilangan bulat
      if (isNaN(price)) {
        return priceString; // Kembalikan string asli jika bukan angka yang valid
      }

      const formattedPrice = price.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return formattedPrice;
    },
    formatRupiah(angka) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(angka);
    },

    submitAngsuran() {
      if (!this.isChecked) {
        Swal.fire({
          title: "Perhatian!",
          text: "Anda harus menyetujui syarat dan ketentuan.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        this.loadingAngsuran = true;

        // Ensure uangMuka is a string
        let uangMuka = this.selectedTenorData.uangMuka;
        if (typeof uangMuka !== "string") {
          uangMuka = uangMuka.toString();
        }

        const uangMukaCleaned = uangMuka.replace(/\D/g, "");

        const selectedBankId = this.selectedBankId;
        const selectedWilayahId = this.selectedWilayahId;
        const requestData = {
          id_produk_grades: this.simulasiPertama.id_produk_grades,
          id_bank: this.selectedTenorData.bank,
          id_wilayah: this.selectedTenorData.wilayah,
          name: this.displayedProfile.name,
          contact_number: "62" + this.displayedProfile.phoneNumber,
          email: this.displayedProfile.email,
          domisili: this.displayedProfile.alamat_lengkap,
          metode_bayar: "Kredit",
          tenor_tahun: this.selectedTenorData.tenor_tahun,
          uang_muka: uangMukaCleaned,
          // id_body: this.simulasiPertama.id_body,
          // id_velg: this.simulasiPertama.id_velg,
          // id_interior: this.simulasiPertama.id_interior,
          tenorBulan: this.selectedTenorData.tenorBulan,
          // otr: this.selectedTenorData.otr,
          dp: this.selectedTenorData.dp,
          // pokokHutangAwal: this.selectedTenorData.pokokHutangAwal,
          // bunga: this.selectedTenorData.bunga,
          // angsuran_pokok: this.selectedTenorData.angsuran_pokok,
          // angsuran_bunga: this.selectedTenorData.angsuran_bunga,
          // angsuran_bulanan: this.selectedTenorData.angsuran_bulanan,
          // biayaAdministrasi: this.selectedTenorData.biayaAdministrasi,
          angsuranPertama: this.selectedTenorData.angsuranPertama,
        };

        const dataawal = this.simulasiPertama;
        const formattedOtr = this.formatRupiah(requestData.otr);
        const formattedDp = this.formatRupiah(requestData.uang_muka);
        const formattedAngsuranPertama = this.formatRupiah(
          requestData.angsuranPertama
        );
        const formattedUnitPrice = this.formatRupiah(dataawal.rangeHarga);
        const formattedVelgPrice = this.formatRupiah(dataawal.velg_price);
        const formattedInteriorPrice = this.formatRupiah(
          dataawal.interior_price
        );
        const formattedGrandTotal = this.formatRupiah(dataawal.grandtotal);
        const tipeMobil = dataawal.name_produk_grades;

        const message = `
Halooo Admin Jaja Auto,
Perkenalkan, Nama saya ${requestData.name}. Saya tertarik untuk mengetahui lebih lanjut tentang penawaran Angsuran di Jaja Auto.

Berikut detail mobil yang saya minati:
Pilihan Tipe : ${tipeMobil}
Pilihan Tenor: ${requestData.tenorBulan} Bulan
Uang Muka: Rp ${formattedDp}
Angsuran Pertama: Rp ${formattedAngsuranPertama}
Harga Total: Rp ${formattedGrandTotal}

Saya berharap dapat mendiskusikan penawaran ini lebih lanjut. Mohon petunjuk selanjutnya.

Terima kasih, ${requestData.name}
`;

        axios
          .post("https://staging-api.jaja.id/order/submit-order", requestData, {
            // headers: {
            //   Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            // },
          })
          .then((response) => {
            this.loadingAngsuran = false;
            Swal.fire({
              title: "Sukses!",
              text: "Data berhasil disubmit. Silahkan cek email anda",
              icon: "success",
              confirmButtonText: "Hubungi kami",
              didOpen: () => {
                const confirmButton = document.querySelector(".swal2-confirm");
                if (confirmButton) {
                  confirmButton.style.height = "50px";
                  confirmButton.style.backgroundColor = "#07528E";
                  confirmButton.style.color = "white";
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const whatsappNumber = "6285281000160";
                const formattedphonecipto = whatsappNumber.replace(/^0/, "");
                window.open(
                  `https://api.whatsapp.com/send?phone=${formattedphonecipto}&text=${encodeURIComponent(
                    message
                  )}`
                );
              }
            });
          })
          .catch((error) => {
            this.loadingAngsuran = false;
            console.error("Error submitting data:", error);
          });
      }
    },

    selectTenor(index) {
      this.isLoading = true;
      this.showForm = true;

      // Scroll ke elemen yang sesuai
      this.$nextTick(() => {
        const element = this.$refs.scrollToElement;
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        this.selectedTenorData = this.simulasiData[index];
      });

      // Menonaktifkan animasi loading setelah 2 detik
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },
  },
};
</script>
<style scoped>
.text-ang-res {
  font-family: Poppins;
  color: var(--Color-Blue-Blue-06, #0f5392);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
@media only screen and (max-width: 440px) {
  .custom-col {
    flex-basis: 50%; /* Kolom akan mengambil setengah lebar pada layar dengan lebar kurang dari 440px */
  }
}
.btn-ajukan-angsuran {
  font-size: 16px;
  color: var(--color-white);
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.btn-ajukan-angsuran:hover {
  background: #fbab44;
  color: black;
}
.card-tenor {
  border-radius: 10px;
  border: 1px solid var(--color-blue-blue-06, #207ace);
  background: var(--color-neutral-neutral-00, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-judul-angsuran {
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  font-family: Poppins;
}
.title-angsuran {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  font-family: Poppins;
}
.ca {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background: #e9f7ff;
}
.cb {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
  background: #e9f7ff;
}
.text-card {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  font-family: Poppins;
}
.ca1 {
  display: flex;
  width: 100%;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;

  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
}
.ta1 {
  color: var(--color-blue-blue-05, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ta2 {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.text-ketentuan {
  font-family: Poppins;
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-jdl-otr {
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.label-form {
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.button {
  /* margin-right: 12px; */
  font-size: 16px;
  color: black;
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  height: 50px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--color-yellow-yellow-05, #fbab44);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button:hover {
  background: #207ace; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow on hover */
}
</style>
