<template>
  <v-app>
    <v-main class="pt-0">
      <!-- <home />
      <about />
      <download />
      <pricing />
      <contact /> -->
      <router-view></router-view>
    </v-main>
    <!-- Tombol Hubungi Kami -->
    
    <v-btn
      dark
      fixed
      bottom
      right
      style="
        background-color: #1F1C1C;
        border: solid white 4px;
        z-index: 1000;
        border-radius: 40px;
        padding: 20px;
        text-transform: none; /* Hindari kapitalisasi teks */
      "
      @click="openWhatsApp"
      
    >
      <span
        style="
          color: white;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.4px;
          font-family: Poppins;
        "
      >
        Hubungi Kami
      </span>
      <img
        style="margin-top: -41px; width: 80px; height: 80px"
        src="./assets/img/foot/alexxa.png"
      />
    </v-btn>

    <v-scale-transition>
      <!-- Tombol Scroll Up -->
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        right
        fixed
        bottom
        @click="toTop"
        style="
          margin-right: 263px;
          z-index: 1000;
          border-radius: 40px;
          height: 45px;
          background-color: white;
          border: solid #1F1C1C 4px;
        "
      >
        <v-icon style="color: #1F1C1C">mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <footer />
  </v-app>
</template>

<script>
import foote from "./components/Footer";
import home from "./components/HomeSection";

export default {
  name: "App",

  components: {
    foote,
    home,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    openWhatsApp() {
      // Redirect ke WhatsApp dengan nomor tertentu
      window.open(
        "https://api.whatsapp.com/send?phone=6285281000160&text=Halo%2C%20saya%20memerlukan%20bantuan%20dengan%20Jaja%20Auto.",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.v-main {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

/* Penyesuaian untuk tombol Hubungi Kami */
.v-btn.bottom.left {
  margin-bottom: 80px; /* Atur jarak dari bawah */
  margin-left: 20px; /* Atur jarak dari kiri */
}
.bg-cs {
  position: relative;
  z-index: 1;
  background-size: cover;
  /* background-color: #054f8b; */
  background-image: url("./assets/img/foot/bg-cs.png");
}
</style>
